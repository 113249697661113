export const useSessionStorage = () => {
  function get(key) {
    if (typeof window !== 'undefined') {
      const sessionStorageItem = sessionStorage.getItem(key);
      // eslint-disable-next-line no-undef
      return sessionStorageItem && JSON.parse(sessionStorageItem);
    }
    return {};
  }

  function set(key, data) {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  return { get, set };
};
