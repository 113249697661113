import { instanceFetch, createAuthorizationHeader } from '@/fetch';
import { TPersonalLicensesDTO } from '@/core/infra';

export const getLicenses = (show = 'all', limit = 10, offset = 0) => {
  const headers = createAuthorizationHeader();

  return instanceFetch
    .get(`/licenses?show=${show}&limit=${limit}&offset=${offset}`, headers)
    .then(({ licenses }) => {
      return licenses as TPersonalLicensesDTO;
    })
    .catch((err) => {
      throw err;
    });
};
