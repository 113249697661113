import { useContextDispatch, useContextState } from '@/components/context';
import { getLicenses } from '@/components/PersonalLicenses/service';
import { LocalStorageService } from '@/helper';
import { useEffect } from 'react';

const usePersonalLicenses = () => {
  const { hasExpiredLicenseAction, personalLicensesAction } = useContextDispatch();
  const { personalLicenses } = useContextState();

  const NOTIFIED_KEY = 'notified-expired-licenses';

  useEffect(() => {
    const expiredIds = getExpiredLicenseIds();
    const notifiedExpiredLicenses = getNotifiedLicenseIds();
    const needNotify = expiredIds.filter((id) => !notifiedExpiredLicenses.includes(id)).length;

    hasExpiredLicenseAction(needNotify);

    if (!expiredIds) {
      LocalStorageService.set(NOTIFIED_KEY, JSON.stringify([]));
    }
  }, [personalLicenses]);

  const getExpiredLicenseIds = () => {
    return personalLicenses
      .filter((l) => l.commerceType === 'PAID' && !(new Date(l?.expirationDate) > new Date()))
      .map((l) => l.licenseId);
  };

  const getNotifiedLicenseIds = () => {
    try {
      return JSON.parse(LocalStorageService.get(NOTIFIED_KEY));
    } catch (e) {
      return [];
    }
  };

  const setNotifiedLicenseIds = () => {
    hasExpiredLicenseAction(false);
    LocalStorageService.set(NOTIFIED_KEY, JSON.stringify(getExpiredLicenseIds()));
  };

  const getPersonalLicenses = () => {
    Promise.all([getLicenses('ALL', 1000), getLicenses('ARCHIVE', 1000)]).then(
      ([recent, archive]) => {
        personalLicensesAction([...recent, ...archive]);
      }
    );
  };

  return {
    personalLicenses,
    getPersonalLicenses,
    getExpiredLicenseIds,
    setNotifiedLicenseIds,
  };
};

export { usePersonalLicenses };
