import getConfig from 'next/config';
import { updateTokenFunc } from '../components/App/service';

const checkErr = ({ data, err }) => {
  if (err) {
    const e: { details?: string; body?: any; statusCode?: number; value?: any } = {};
    e.details = data.message || 'bad request';
    e.body = data.error;
    e.statusCode = err;
    e.value = {
      data,
      err,
    };
    e.value = { ...data };
    throw e;
  }
  return data;
};

const toJson = async ({ res, err }: { res: any; err?: any }) => {
  const type = res.headers.get('Content-Type');
  if (!type) {
    return {
      data: '',
      err,
    };
  }
  if (type.includes('json')) {
    try {
      const data = await res.json();
      return {
        data,
        err,
      };
    } catch (error) {
      return {
        data: '',
        err,
      };
    }
  }
  try {
    const data = await res.text();
    return {
      data,
      err,
    };
  } catch (error) {
    return {
      data: '',
      err,
    };
  }
};

const checkResponse = (res): { res: string; err?: any } => {
  const isUploadFileWithNoCors = res.type === 'opaque';
  const { ok } = res;

  if (ok || isUploadFileWithNoCors) {
    return { res };
  }

  const { status, statusText } = res;
  const err = status || statusText;
  return { res, err };
};

const fetchData = async <T>(url, method, body, headers = null): Promise<T> => {
  const {
    publicRuntimeConfig: { authApi },
  } = getConfig();

  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const options = {
    method,
    headers: headers ? Object.assign(defaultHeaders, headers) : defaultHeaders,
  };
  if (body) {
    Object.assign(options, { body: JSON.stringify(body) });
  }
  const apiUrl = `${authApi}${url}`;
  // eslint-disable-next-line no-useless-catch
  try {
    const data = await fetch(apiUrl, { ...options });
    const result = checkResponse(data);
    const result_1 = await toJson(result);
    return checkErr(result_1);
  } catch (err) {
    // @ts-ignore
    if (typeof err === 'object' && err?.statusCode! === 401) {
      // @ts-ignore
      const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));

      if (refreshToken) {
        try {
          await updateTokenFunc({ refreshToken });
          // @ts-ignore
          return await fetchData(url, method, body, createAuthorizationHeader());
        } catch (e) {
          window.location.replace('/login');
        }
      }
      // @ts-ignore
    } else if (err?.statusCode! === 403) {
      window.location.replace('/login');
    }
    throw err;
  }
};

const fetchToken = async (url, method, body, headers = null) => {
  const {
    publicRuntimeConfig: { authApi },
  } = getConfig();

  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const options = {
    method,
    headers: headers ? Object.assign(defaultHeaders, headers) : defaultHeaders,
  };
  if (body) {
    Object.assign(options, { body: JSON.stringify(body) });
  }
  const apiUrl = `${authApi}${url}`;
  // eslint-disable-next-line no-useless-catch
  try {
    const data = await fetch(apiUrl, { ...options });
    const result = checkResponse(data);
    const result_1 = await toJson(result);
    return checkErr(result_1);
  } catch (err) {
    throw err;
  }
};

const instanceFetch = {
  get: async <T = any>(url, headers: any = null) => fetchData<T>(url, 'GET', null, headers),
  post: async <T = any>(url, body, headers: any = null) => fetchData<T>(url, 'POST', body, headers),
  patch: async <T = any>(url, body, headers: any = null) =>
    fetchData<T>(url, 'PATCH', body, headers),
  put: async <T = any>(url, body, headers: any = null) => fetchData<T>(url, 'PUT', body, headers),
  delete: async <T = any>(url, headers: any = null) => fetchData<T>(url, 'DELETE', headers),
};

const instanceTokenFetch = {
  get: async (url, headers: any = null) => fetchToken(url, 'GET', null, headers),
  post: async (url, body, headers: any = null) => fetchToken(url, 'POST', body, headers),
};

const createAuthorizationHeader = () => {
  // @ts-ignore
  const accessToken = JSON.parse(localStorage.getItem('accessToken'));
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return null;
};

export { instanceFetch, createAuthorizationHeader, instanceTokenFetch };
