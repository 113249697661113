import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useContextState } from '../context';
import { usePersonalLicenses } from '@/src/hooks/usePersonalLicenses';
import CloseIcon from '../../public/img/icons/icon_close.svg';

const LicenseExpiredBanner = () => {
  const { t } = useTranslation();
  const { hasExpiredLicense } = useContextState();
  const { setNotifiedLicenseIds } = usePersonalLicenses();
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalRoot(document.getElementById('portal-root'));
  }, [hasExpiredLicense]);

  const closeNotification = () => {
    setNotifiedLicenseIds();
  };

  if (!hasExpiredLicense || !portalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="notification">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: t('expired-licenses_message') }} />
      <button className="notification-btn" type="button" onClick={closeNotification}>
        <CloseIcon />
      </button>
    </div>,
    portalRoot
  );
};

export default LicenseExpiredBanner;
