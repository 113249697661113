import React, { useEffect, memo } from 'react';
import { useContextState, useContextDispatch } from '../context';

const Notification = () => {
  const {
    notification: { text, color, global },
  } = useContextState();
  const { notificationClearAction } = useContextDispatch();

  useEffect(() => {
    if (text) {
      setTimeout(notificationClearAction, 3000);
    }
  }, [notificationClearAction, text]);

  if (text && global) {
    return (
      <div className={`notification ${color}`}>
        <div>{text}</div>
      </div>
    );
  }
  return null;
};

export default memo(Notification);
