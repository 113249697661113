import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
// @ts-ignore
import CloseIcon from '../../public/img/icons/icon_close.svg';
import { useSessionStorage } from '../../src/hooks/useSessionStorage';
import { useRouter } from 'next/router';

const DownloadNotificationBanner = () => {
  const { get, set } = useSessionStorage();
  const router = useRouter();
  let notificationActive = get('downloadNotification');
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setPortalRoot(document.getElementById('portal-root'));
  }, []);

  const closeNotification = () => {
    setIsActive(false);
    set('downloadNotification', false);
  };

  useEffect(() => {
    if (notificationActive) {
      setIsActive(notificationActive);
    }
  }, [notificationActive]);

  // @ts-ignore
  useEffect(() => {
    if (isActive) {
      const timeout = setTimeout(() => {
        set('downloadNotification', false);
        setIsActive(false);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isActive]);
  return isActive && portalRoot
    ? ReactDOM.createPortal(
        <div className="notification download-notification">
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className="download-notification-text"
            dangerouslySetInnerHTML={{ __html: t('download_notification_text') }}
          />
          <button
            onClick={() => router.push('/terminal/download/TigerTradeSetup.exe')}
            className="download-notification-button"
          >
            {' '}
            {t('download_notification_button')}
          </button>
          <button className="notification-btn" type="button" onClick={closeNotification}>
            <CloseIcon />
          </button>
        </div>,
        portalRoot
      )
    : null;
};

export default DownloadNotificationBanner;
