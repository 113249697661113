import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';

export const trackingId = 'UA-202414358-1';

export const dataGA = (status, licensesPaid) => {
  const selectedStatus = status === 'success' ? 'successful' : 'canceled';
  const firstPurchase = licensesPaid === 0 ? '_first' : '';

  return [
    {
      label: 'TigerX1',
      category: 'REVENUE',
      action: `purchase_${selectedStatus}_x1${firstPurchase}`,
      value: 49,
    },
    {
      label: 'TigerX3',
      category: 'REVENUE',
      action: `purchase_${selectedStatus}_x3${firstPurchase}`,
      value: 124,
    },
    {
      label: 'TigerX6',
      category: 'REVENUE',
      action: `purchase_${selectedStatus}_x6${firstPurchase}`,
      value: 224,
    },
    {
      label: 'TigerX12',
      category: 'REVENUE',
      action: `purchase_${selectedStatus}_x12${firstPurchase}`,
      value: 399,
    },
    {
      label: 'Player',
      category: 'REVENUE',
      action: `purchase_${selectedStatus}_player${firstPurchase}`,
      value: 15,
    },
  ];
};

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const eventAnalytics = ({ category, action, label, value }: AnalyticsEvent) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
  ym('reachGoal', action);
};
