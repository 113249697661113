import { debounce } from 'lodash';
import { instanceTokenFetch } from '@/fetch';
import { LocalStorageService } from '@/helper';

export const updateTokenFunc = async (body): Promise<any> => {
  try {
    return await instanceTokenFetch
      .post('/users/token/refresh', body)
      .then(({ accessToken, refreshToken }) => {
        LocalStorageService.set('accessToken', accessToken);
        LocalStorageService.set('refreshToken', refreshToken);
        localStorage.setItem('accessToken', JSON.stringify(accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      })
      .catch(() => {
        window.location.replace('/terminal/login');
        LocalStorageService.remove('accessToken');
        LocalStorageService.remove('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      });
  } catch (error) {
    throw error;
  }
};

export const updateToken = (body) => debounce(() => updateTokenFunc(body), 500);
