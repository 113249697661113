import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/uk';
import jwt_decode from 'jwt-decode';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as ReactGA from 'react-ga';
import { useIntercom } from 'react-use-intercom';
import { YMInitializer } from 'react-yandex-metrika';
import { isMobileDevice, LocalStorageService, parseJwt } from '@/helper';
import { useLocalStorage } from '@/src/hooks/useLocalStorage';
import { initGA, trackingId } from '@/src/utils/analytics';
import Notification from '../common/Notification';
import { useContextDispatch, useContextState } from '../context';
import DownloadNotificationBanner from '../DownloadNotificationBanner/DownloadNotificationBanner';
import LicenseExpiredBanner from '../LicenseExpiredBanner/LicenseExpiredBanner';
import { updateToken } from './service';
import { usePersonalLicenses } from '@/src/hooks/usePersonalLicenses';

const CookieBanner = dynamic(() => import('../CookieBanner/CookieBanner'));
const Header = dynamic(() => import('../Header/Header'));
const Footer = dynamic(() => import('../Footer/Footer'));
const HeadComponent = dynamic(() => import('../Head'));
const SurveyPopup = dynamic(() => import('../SurveyPopup/SurveyPopup'));

interface AppProps {
  children?: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const { authorizedAction, userAction, isMobileDeviceAction } = useContextDispatch();
  const { authorized } = useContextState();
  const { push, pathname, locale, asPath, query } = useRouter();
  const { set } = useLocalStorage();
  const { boot, shutdown } = useIntercom();
  const { getPersonalLicenses } = usePersonalLicenses();

  useEffect(() => {
    moment.locale(locale === 'ua' ? 'uk' : locale);
  }, [locale]);

  useEffect(() => {
    if (asPath !== '/terminal/login') {
      set('previousPage', asPath);
    }
    if (query.clickid !== undefined) {
      set('clickId', query?.clickid);
    }
    if (query.pid) {
      LocalStorageService.remove('referral_user_id');
      LocalStorageService.set('aff_id', query?.pid);
    }
  }, [query, set]);

  useEffect(() => {
    const savedUtm = sessionStorage.getItem('utm');
    const isUtm = /utm_source/i.test(asPath);

    if (isUtm || savedUtm) {
      const utmIndex = asPath.indexOf('utm_source');
      let utmPath = asPath.slice(utmIndex);
      if (utmIndex === -1 && savedUtm) {
        utmPath = savedUtm;
      }
      sessionStorage.setItem('utm', utmPath);
      window.history.replaceState(null, '', `?${utmPath}`);
      return;
    }
    sessionStorage.setItem('utm', '');

    if (typeof window !== undefined) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }
  }, [asPath, locale, pathname]);

  const getBrowser = () => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  const getOS = () => {
    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1) OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1) OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'MacOS';
    if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
    if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';
    return OSName;
  };

  useEffect(() => {
    if (authorized) {
      getPersonalLicenses();
    }
  }, [authorized, locale]);

  useEffect(() => {
    const refreshToken = LocalStorageService.get('refreshToken');

    if (refreshToken && !authorized) {
      updateToken({ refreshToken });
      authorizedAction(true);
    }

    if (authorized) {
      const { email, username } = parseJwt();
      userAction({
        name: username || email,
        email,
      });
    }
  }, [authorized, authorizedAction, userAction, locale, pathname]);

  useEffect(() => {
    const [browser, browserVer] = getBrowser().split(' ');
    const customAttributes = {
      os: getOS(),
      browser,
      browser_version: browserVer,
    };

    if (authorized) {
      const { email, username, userId } = parseJwt();
      userAction({
        name: username || email,
        email,
      });
      boot({
        userId,
        email,
        name: username,
        languageOverride: locale,
        customLauncherSelector: '#my-launcher',
        customAttributes,
        backgroundColor: '#ff721c',
      });
    } else {
      boot({
        languageOverride: locale,
        customAttributes,
        customLauncherSelector: '#my-launcher',
        backgroundColor: '#ff721c',
      });
    }
  }, [boot, authorized]);

  useEffect(() => {
    const value = isMobileDevice();
    isMobileDeviceAction(value);
  }, [isMobileDeviceAction]);

  useEffect(() => shutdown, []);

  useEffect(() => {
    const accessToken = LocalStorageService.get('accessToken');
    if (authorized && !!accessToken) {
      const token = jwt_decode(accessToken) as any;
      ReactGA.set({
        userId: token.userId,
      });
    }
  }, [authorized]);

  useEffect(() => {
    initGA(trackingId);
  }, []);

  return (
    <>
      <YMInitializer accounts={[83322997]} />
      <HeadComponent />
      <Notification />
      <Header />
      <CookieBanner />
      <LicenseExpiredBanner />
      <DownloadNotificationBanner />
      {children}
      <SurveyPopup isEnabled={authorized ?? false} />
      <Footer />
      <div className="page-fader" />
      <div id="portal-root" />
    </>
  );
};

export default App;
